<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Записи</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <!-- <b-button
            variant="primary"
            :to="{ name: 'apps-invoice-add'}"
          >
          Додати запис
          </b-button> -->
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="10"
        >
          <div class="d-flex align-items-center justify-content-end">
            <!-- <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Пошук..."
            /> -->
            <v-select
              v-model="driverFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="driversOptions"
              :reduce="val => val.value"
              class="invoice-filter-select mr-1"
              placeholder="Виберіть водія"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
            <b-form-group class="mb-0 mr-1">             
              <flat-pickr
                v-model="dateFilter"
                class="form-control"
                :config="{ mode: 'range', locale: { rangeSeparator: ' - ' }}"
                placeholder="Дата"
              />
            </b-form-group>
            <v-select
              v-if="transactionStatuses"
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="transactionStatuses"
              label="translated"
              class="invoice-filter-select mr-1"
              placeholder="Виберіть статус"
            >
            </v-select>
            <v-select
              v-model="cashTypeFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="cashTypeOptions"
              :reduce="val => val.value"
              class="invoice-filter-select"
              placeholder="Виберіть тип рахунку"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refBalanceListTable"
      :items="fetchBalances"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Відповідних записів не знайдено"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #head(invoiceStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        <b-link
          @click="showModal(data.item)"
          class="font-weight-bold"
        >
          #{{ data.item.number }}
        </b-link>
      </template>

      <!-- Column: Balance Status -->
      <template #cell(invoiceStatus)="data">
        <b-avatar
          :id="`invoice-row-${data.item.number}`"
          size="32"
          :variant="`light-${resolveBalanceStatusVariantAndIcon(data.item.directionId).variant}`"
        >
          <feather-icon
            :icon="resolveBalanceStatusVariantAndIcon(data.item.directionId).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`invoice-row-${data.item.number}`"
          placement="top"
        >
          <!-- <p class="mb-0">
            {{ data.item.invoiceStatus }}
          </p> -->
          <p class="mb-0">
            {{ data.item.directionId == 1 ? 'Поповнення' : 'Зняття' }}
          </p>
          <!-- <p class="mb-0">
            Термін виконання: {{ data.item.dueDate }}
          </p> -->
        </b-tooltip>
      </template>

      <!-- Column: Driver -->
      <template #cell(driver)="data">
        <b-media 
          vertical-align="center" class="align-items-center"  
        >
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.driverFullName)"
              :variant="`light-${resolveClientAvatarVariant(data.item.directionId)}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.driverFullName ? data.item.driverFullName : '-' }}
          </span>
        </b-media>
      </template>

      <!-- Column: Client -->
      <template #cell(manager)="data">
        <b-media vertical-align="center" class="align-items-center" >
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.madeByManagerFullName)"
              :variant="`light-${resolveClientAvatarVariant(data.item.directionId)}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.madeByManagerFullName ? data.item.madeByManagerFullName : '-' }}
          </span>
        </b-media>
      </template>

      <template #cell(typeAccount)="data">
        <b-badge
          pill
          :variant="`light-${resolveCashVariant(data.item.typeAccount)}`"
          class="text-capitalize"
        >
          {{ resolveCash(data.item.typeAccount) }}
        </b-badge>
      </template>

      <template #cell(isExecuted)="data">
        <b-badge
          pill
          :variant="`light-${resolveStatusVariant(data.item.simplifiedStatusId)}`"
          class="text-capitalize"
        >
          {{ resolveStatus(data.item.simplifiedStatusId) }}
        </b-badge>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">

          <feather-icon
            :id="`invoice-row-${data.item.keyId}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="showModal(data.item)"
          />
          <b-tooltip
            :target="`invoice-row-${data.item.keyId}-preview-icon`"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="showModal(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Переглянути</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Показано {{ dataMeta.from }} до {{ dataMeta.to }} з {{ dataMeta.of }} записів</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalBalances"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <b-modal
      v-model="modalShow"
      :title="'Транзакція #'+modalItem.keyId"
      ok-title="Закрити"
      ok-only
    >

      <h4 class="mb-2">Транзакція #{{ modalItem.keyId }}</h4>
      <div class="invoice-date-wrapper">
        <p class="invoice-date-title">
          Дата створення:
        </p>
        <p class="invoice-date">
          {{ modalItem.createdAt }}
        </p>
      </div>
      <div class="invoice-date-wrapper">
        <p class="invoice-date-title">
          Дата зарахування:
        </p>
        <p class="invoice-date">
          {{ modalItem.executedAt }}
        </p>
      </div>
      <div class="invoice-date-wrapper">
        <p class="invoice-date-title">
          Відправник:
        </p>
        <p class="invoice-date">
          {{ modalItem.directionId == 1 ? modalItem.madeByManagerFullName : modalItem.driverFullName }}
        </p>
      </div>
      <div class="invoice-date-wrapper mb-2">
        <p class="invoice-date-title">
          Отримувач:
        </p>
        <p class="invoice-date">
          {{ modalItem.directionId == 1 ? modalItem.driverFullName : modalItem.madeByManagerFullName }}
        </p>
      </div>

      <h5>ОПИС</h5>
      <p class="mb-3">
        {{ (modalItem.directionId == 1 ? 'Поповнення ' : 'Зняття з ') + (modalItem.typeAccount == 1 ? 'готівкового' : 'безготівкового') + ' рахунку' }}
      </p>

      <h5>СУМА</h5>
      <p>
        {{ (modalItem.amount / 100).toFixed(2) }} грн
      </p>
      
    </b-modal>

  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BModal,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import {onBeforeMount, onUnmounted} from '@vue/composition-api'
import store from '@/store'
import useBalancesList from './useBalanceList'
import flatPickr from 'vue-flatpickr-component'

import balanceStoreModule from '../balanceStoreModule'
import axiosIns from "@axios";
import { useTransactionStatuses } from "@/composition/formOptions/useTransactionStatuses";

export default {
  components: {
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BModal,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      driversOptions: [],
      modalShow: false,
      modalItem: [],
    }
  },
  methods: {
    getDrivers: async function() {

      let driversOptions = []

      const response = await axiosIns
          .get('/business/drivers')
          .then(response => {
              return response.data.items
          })
          .catch(() => {
              return false
          })

      response.forEach(driver => {
          driversOptions.push({
            label: driver.fullName,
            value: driver.profileId,
          })
      });

      return driversOptions
    },
    showModal(item) {
      this.modalItem = item
      this.modalShow = !this.modalShow
    }
  },
  async mounted() {
    this.driversOptions = await this.getDrivers()
  },
  setup() {

    const INVOICE_APP_STORE_MODULE_NAME = 'app-balance'

    const { fetchTransactionStatuses, getTransactionStatusesTitle, transactionStatuses } = useTransactionStatuses();

    onBeforeMount(() => {
      fetchTransactionStatuses()
    })

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, balanceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchBalances,
      tableColumns,
      perPage,
      currentPage,
      totalBalances,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBalanceListTable,

      statusFilter,
      driverFilter,
      cashTypeFilter,
      dateFilter,

      statusOptions,
      cashTypeOptions,

      refetchData,

      resolveBalanceStatusVariantAndIcon,
      resolveClientAvatarVariant,

      resolveCashVariant,
      resolveCash,

      resolveStatusVariant,
      resolveStatus,
      getDrivers,

    } = useBalancesList()

    return {
      fetchBalances,
      tableColumns,
      perPage,
      currentPage,
      totalBalances,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBalanceListTable,

      statusFilter,
      driverFilter,
      cashTypeFilter,
      dateFilter,

      refetchData,

      statusOptions,
      cashTypeOptions,
      transactionStatuses,
      getTransactionStatusesTitle,

      avatarText,
      resolveBalanceStatusVariantAndIcon,
      resolveClientAvatarVariant,

      resolveCashVariant,
      resolveCash,

      resolveStatusVariant,
      resolveStatus,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.feather-eye {
  cursor: pointer;
}

.invoice-date-wrapper {
  display: flex;
  align-items: center;
}

.invoice-date-title {
  font-weight: 600;
  margin-right: 10px;
}

.invoice-filter-select {
  min-width: 250px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>